/* Generated by restful-react */

import React from "react";
import { Get, GetProps, useGet, UseGetProps } from "restful-react";
export const SPEC_VERSION = "1.0.0"; 
export interface Country {
  name: string;
  lat: number;
  lng: number;
  cities: City[];
}

export interface City {
  id: number;
  name: string;
  imageSrc: string;
  lat: number;
  lng: number;
  shortDescription: string;
  longDescription: string;
  country: Country;
}

export interface WetterTag {
  date: number;
  temperaturen: number[];
}

export interface GetCityInformationPathParams {
  city: string
}

export type GetCityInformationProps = Omit<GetProps<string, unknown, void, GetCityInformationPathParams>, "path"> & GetCityInformationPathParams;

export const GetCityInformation = ({city, ...props}: GetCityInformationProps) => (
  <Get<string, unknown, void, GetCityInformationPathParams>
    path={`/cityInformation/${city}`}
    base="https://tinfbackend.freemine.de/api"
    
    {...props}
  />
);

export type UseGetCityInformationProps = Omit<UseGetProps<string, unknown, void, GetCityInformationPathParams>, "path"> & GetCityInformationPathParams;

export const useGetCityInformation = ({city, ...props}: UseGetCityInformationProps) => useGet<string, unknown, void, GetCityInformationPathParams>((paramsInPath: GetCityInformationPathParams) => `/cityInformation/${paramsInPath.city}`, { base:"https://tinfbackend.freemine.de/api", pathParams: { city }, ...props });


export type GetCountriesProps = Omit<GetProps<string[], unknown, void, void>, "path">;

export const GetCountries = (props: GetCountriesProps) => (
  <Get<string[], unknown, void, void>
    path={`/countries`}
    base="https://tinfbackend.freemine.de/api"
    
    {...props}
  />
);

export type UseGetCountriesProps = Omit<UseGetProps<string[], unknown, void, void>, "path">;

export const useGetCountries = (props: UseGetCountriesProps) => useGet<string[], unknown, void, void>(`/countries`, { base:"https://tinfbackend.freemine.de/api",  ...props });


export interface GetTravelDestinationForCountryPathParams {
  country: string
}

export type GetTravelDestinationForCountryProps = Omit<GetProps<Country, unknown, void, GetTravelDestinationForCountryPathParams>, "path"> & GetTravelDestinationForCountryPathParams;

export const GetTravelDestinationForCountry = ({country, ...props}: GetTravelDestinationForCountryProps) => (
  <Get<Country, unknown, void, GetTravelDestinationForCountryPathParams>
    path={`/travelDestinations/${country}`}
    base="https://tinfbackend.freemine.de/api"
    
    {...props}
  />
);

export type UseGetTravelDestinationForCountryProps = Omit<UseGetProps<Country, unknown, void, GetTravelDestinationForCountryPathParams>, "path"> & GetTravelDestinationForCountryPathParams;

export const useGetTravelDestinationForCountry = ({country, ...props}: UseGetTravelDestinationForCountryProps) => useGet<Country, unknown, void, GetTravelDestinationForCountryPathParams>((paramsInPath: GetTravelDestinationForCountryPathParams) => `/travelDestinations/${paramsInPath.country}`, { base:"https://tinfbackend.freemine.de/api", pathParams: { country }, ...props });


export type GetWetterHeuteProps = Omit<GetProps<WetterTag, unknown, void, void>, "path">;

export const GetWetterHeute = (props: GetWetterHeuteProps) => (
  <Get<WetterTag, unknown, void, void>
    path={`/wetterHeute`}
    base="https://tinfbackend.freemine.de/api"
    
    {...props}
  />
);

export type UseGetWetterHeuteProps = Omit<UseGetProps<WetterTag, unknown, void, void>, "path">;

export const useGetWetterHeute = (props: UseGetWetterHeuteProps) => useGet<WetterTag, unknown, void, void>(`/wetterHeute`, { base:"https://tinfbackend.freemine.de/api",  ...props });

